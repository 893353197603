import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISlider, ISliderConfig } from '@bs/models/cms/slider';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SlidersService {

  readonly url = `${this.config.api.cms}/sliders`;
  private _sliders = this.http.get<Array<ISlider>>(`${this.url}`).pipe(shareReplay(1));

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  all(): Observable<ISlider[]> {
    return this._sliders;
  }

  put(sliderId: number, value: { banners: Array<number>, config: ISliderConfig }) {
    return this.http.put(`${this.url}/${sliderId}`, value);
  }
}
